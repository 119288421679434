
import './Header.scss';
import FontAwesomeIcon from '../FontAwesome';
import { MenuContext } from './MenuContext';
import { useContext } from 'react';
import { ViewSelector } from '../Views/ViewSelector';

import { Logout } from '../Services/Logout';

export const Header = () => {

    const menuContext = useContext(MenuContext);

    return (
      <header className="header">

        <div className="header-start-container">
          <div className="header-menu-button" onClick={()=>menuContext.toggleOpen()} >
            <FontAwesomeIcon className="header-menu-button-icon" icon={["fas", "bars"]} />
          </div>
          <img className="header-logo-desktop desktop-only" src="/images/fuelservice-words-horizontal.svg" alt="fuelService logo" />
          <img className="header-logo-mobile mobile-only" src="/images/fuelservice-logo.svg" alt="fuelService logo" />
        </div>

        <div className="header-end-container">
          <ViewSelector />
          <FontAwesomeIcon border title="Logout" className="header-logout-icon desktop-only" onClick={Logout} icon="fa-solid fa-arrow-right-from-bracket" />
        </div>

      </header>
    )
}