import './Issues.scss';
import {useContext, useState, useEffect} from 'react';

import BrokenPhoneCard from './BrokenPhoneCard';
import NonWorkingStationsCard from './NonWorkingStationsCard';
import { LoadingData } from '../Components/LoadingData';

import { AppContext } from '../AppContext';
import { getAllIssues } from '../Services/IssuesService';
import MobileRotate from '../Components/MobileRotate/MobileRotate';

export const Issues = () => {

    const context = useContext(AppContext);

    const [brokenData, setBrokenData] = useState([]);
    const [otherData, setOtherData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(()=> {
        async function loadData() {
          try {
              setLoading(true);
              const result = await getAllIssues(context.token, context.activeView);
              setBrokenData(result.filter(obj => {
                return obj.last_result === 'broken';
              }));
              setOtherData(result.filter(obj => {
                return obj.last_result !== 'broken';
              }));
              setLoading(false);
          } catch (error) {
              console.log(error);
          }
        }
        loadData();
    },[context.token, context.activeView]);

    return(
        <div className="issues">
            <MobileRotate />
            <LoadingData loading={loading} />
            {brokenData.length > 0 && <BrokenPhoneCard data={brokenData}/> }
            <NonWorkingStationsCard data={otherData}/>
        </div>
    )
}

export default Issues;