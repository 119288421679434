import {useMemo} from 'react';

import Table from './Table';
 
export const StationsSummaryTable = ({data, searchString}) => {

    const columns = useMemo(
        () => [
            {
                Header: 'Type',
                accessor: 'type', // accessor is the "key" in the data
            },
            {
                Header: 'Accepted',
                accessor: 'accept',
            },
            {
                Header: 'Declined',
                accessor: 'decline',
            },
            {
                Header: 'Phone Busy',
                accessor: 'engaged',
            },
            {
                Header: 'Not Answered',
                accessor: 'noanswer',
            },
            {
                Header: 'Hung-up',
                accessor: 'hungup',
            },
            {
                Header: 'Phone Broken',
                accessor: 'broken',
            },
            {
                Header: 'Total',
                accessor: 'total',
            },
        ],
        []
    );
    
    return (
        <div>

            <Table data={data} columns={columns} searchString={searchString} allowSearch/>

        </div>
    )
}

