import logoImage from './fuelservice-words-horizontal-white.svg';
import { AppContext } from '../AppContext';
import { useState, useEffect, useContext } from 'react';

import './LoginPage.scss';
import { requestAuthentication, verifyAuthentication } from '../Services/AuthenticationService';
import { useParams, useNavigate } from 'react-router-dom';
import FontAwesomeIcon from '../FontAwesome';

import Popup from 'reactjs-popup';

export const LoginPage = () => {

    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(false);

    const [emailSent, setEmailSent] = useState(false);
    const [error, setError] = useState(null);

    const [verified, setVerified] = useState(false);

    const [loginExpired, setLoginExpired] = useState(false);

    const [token, setToken] = useState(null);

    const params = useParams();

    const navigate = useNavigate();

    const context = useContext(AppContext);

    useEffect(()=>{
        if(context.token) {
            navigate('/main');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[context.token]);

    // Check for login id
    useEffect(()=>{
        async function verify (id) {
            const verification = await verifyAuthentication(id);

            if(verification.result === 'success') {
                setToken(verification.token);
                context.setUserDetails(verification);
                context.setVerifyingToken(false);
                setVerified(true);
            } else {
                context.setVerifyingToken(false);
                setLoginExpired(true);
            }
        }
        if(params.id) {
            context.setVerifyingToken(true);
            verify(params.id);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[params.id])

    // Validate the email
    useEffect(()=>{
        setEmailValid(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(email));
    },[email])

    async function clickLogin(event) {
        event.preventDefault(); // when pressing enter on the email submission
        if(emailValid) {
            const result = await requestAuthentication(email);
            if(result.result === 'success') {
                setEmailSent(true);
                setError(null);
            } else {
                setError(result);
            }
            console.log(result);
        }
    }

    function saveUserLogin() {
        window.localStorage.setItem('token', token);
        loggedIn();
    }

    function loggedIn() {
        context.setToken(token);
        navigate('/main');
    }

    function getNewLink() {
        setEmail('');
        setEmailValid(false);
        setEmailSent(false);
        context.setVerifyingToken(false);
        setVerified(false);
        setLoginExpired(false);
        navigate('/');
    }

    return(
        <AppContext.Consumer>
            {(appState) => (
                <div className="body">
                    <div className="container">
                        
                        <div className="form">

                            <div className="left">
                                <img className="logo" src={logoImage} alt="fuelservice logo" />
                                <h1>Station Dashboard</h1>
                            </div>

                            {!emailSent && !loginExpired && !appState.verifyingToken &&
                                <div className="right">
                                    
                                    {error &&
                                        <div className="errorMessage">
                                            {error.errorMessage}
                                        </div>
                                    }
                                    <form onSubmit={clickLogin}>
                                        <input type="email" className="emailInput" placeholder="email address" onChange={(event) => setEmail(event.target.value)}/>
                                    </form>
                                    <button onClick={clickLogin} className="loginButton" disabled={!emailValid}>Login</button>
                                </div>
                            }

                            {emailSent && !appState.verifyingToken &&
                                <div className="rightEmail">
                                    <div><FontAwesomeIcon color="rgba(0,76,152,1)" size="6x" icon={["far", "envelope-open-text"]} /></div>
                                    <div className="rightEmailText">
                                        <div>You should shortly receive an email with a link to login</div>
                                        <div>&nbsp;</div>
                                        <div>If the email doesn't arrive, please check your junk email.</div>
                                    </div>
                                </div>
                            }

                            {appState.verifyingToken && 
                                <div className="rightLoggingIn">
                                    <div className="rightLoggingInText">Logging You In...</div>
                                    <FontAwesomeIcon spin size="6x" color="rgba(0,76,152,1)" secondary-color="red" icon={["far", "circle-notch"]}/>
                                </div>
                            }

                            {loginExpired &&
                                <div className="rightEmail">
                                <div><FontAwesomeIcon style={{"--fa-secondary-color": "red", "--fa-primary-color": "rgba(0,76,152,1)", "--fa-secondary-opacity": 1}} size="6x" icon={["fad", "user-clock"]} /></div>
                                <div className="rightEmailText">
                                    <div>Sorry, the authentication link has expired</div>
                                    <div>&nbsp;</div>
                                    <button className="loginButton" onClick={getNewLink}>Get New Link</button>
                                </div>
                            </div>
                            }

                        </div>

                    </div>

                    <Popup
                        open={verified}
                        position="center"
                        className="saveAuthPopup"
                        closeOnDocumentClick={false}
                        closeOnEscape={false}
                        >
                        <div className="saveAuthPopupContainer">
                             <div className="saveAuthPopupHeader">Save Login Details?</div>
                             <div className="saveAuthPopupMain">
                                 <div>Do you want to save the login details so you don't have to authenticate next time?</div>
                                 <div className="saveAuthPopupMainHint">For security reasons, please only do this on a private device owned by you.</div>
                                 <div className="saveAuthPopupButtons">
                                     <button className="yesButton" onClick={saveUserLogin}>
                                         Yes
                                     </button>
                                     <button className="noButton" onClick={loggedIn}>
                                         No
                                     </button>
                                 </div>
                             </div>
                        </div>
                    </Popup>
                </div>
            )}
        </AppContext.Consumer>
    )
}

export default LoginPage;