import { useContext } from 'react';
import { AppContext } from '../AppContext';
import './ViewSelector.scss';
import FontAwesomeIcon from '../FontAwesome';

export const ViewSelector = () => {

    const context = useContext(AppContext);

    return (
        <div className="view-selector-container">
            <div className="view-selector-label"><FontAwesomeIcon icon="fa-regular fa-eye" /></div>
            <select className="view-selector" onChange={(e)=>context.setActiveView(e.target.value)} value={context.activeView}>
                {context.views.map(view =>
                    <option key={view.viewId} value={view.viewId}>{view.name}</option>
                )}
            </select>
        </div>
    )
}