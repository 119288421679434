import FetchPostJson from "./Fetch";

import hostname from './Hostname';

export const requestAuthentication = async (email) => {
    return (await FetchPostJson(`https://${hostname}/Auth/requestAuth`, {email, origin: window.location.origin}));
}

export const verifyAuthentication = async (id) => {
    return (await FetchPostJson(`https://${hostname}/Auth/verifyAuth`, {id}));
}

export const verifyToken = async (token) => {
    return (await FetchPostJson(`https://${hostname}/Auth/verifyToken`, {token}));
}