import {useContext, useState, useEffect} from 'react';

import { Card, CardTitle, CardHeader, CardCSVExportButton, CardHeaderCenter, CardMain, CardHeaderSearch } from "../Components/Card";
import { LoadingData } from '../Components/LoadingData';
import { StationsTable } from "../Tables/StationsTable";

import { AppContext } from '../AppContext';
import { getAllStations } from '../Services/StationsService';

import './StationsCard.scss';

export const StationsCard = () => {

    const context = useContext(AppContext);
    
    const [data, setData] = useState([]);

    const [search, setSearch] = useState('');
    const [searchData, setSearchData] = useState([]);
    const [loading, setLoading] = useState(false);

    function runSearch(searchString) {

        setSearch(searchString);
        const filteredData = data.filter((item) => {
            return Object.values(item).join('').toLowerCase().includes(searchString.toLowerCase())
            });
        setSearchData(filteredData);
    }
    
    useEffect(()=> {
        async function loadData() {
          try {
              setLoading(true);
              setSearch('');
              const result = await getAllStations(context.token, context.activeView);
            //   console.log(result);
              setData(result);
              setSearchData(result);
              setLoading(false);
          } catch (error) {
              console.log(error);
          }
        }
        loadData();
    },[context.token, context.activeView]);

    return(
        <Card className="stations-card">
            <LoadingData loading={loading} />
            <CardHeader>
                <CardTitle>Stations</CardTitle>
                <CardHeaderCenter justifyContent="flex-start">
                   <CardHeaderSearch value={search} onChange={runSearch} />
                </CardHeaderCenter>
                <CardCSVExportButton data={data} filename="stations.csv"/>
            </CardHeader>
            <CardMain className="stations-main-content">
                <StationsTable data={searchData} />
            </CardMain>                
        </Card>
    )
}

export default StationsCard;