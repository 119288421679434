import { ResponsiveContainer, BarChart, XAxis, YAxis, Tooltip, Bar } from "recharts";

export const UsagePerUserChart = ({data}) => {

    return(
        <ResponsiveContainer width="99%" aspect={3}>
            <BarChart data={data}>
                <XAxis dataKey="number_used" />
                <YAxis />
                <Tooltip
                    labelFormatter={(value) => `Used ${value} times.`}
                />

                <Bar dataKey="amount" fill="#8884d8" name="Amount of users"/>
            </BarChart>
        </ResponsiveContainer>
    );
}