import {useContext, useState, useEffect} from 'react';

import { Card, CardTitle, CardHeader, CardCSVExportButton, CardHeaderCenter, CardMain, CardHeaderDateRange } from "../Components/Card";
import { StationsSummaryTable } from "../Tables/StationsSummaryTable";

import { AppContext } from '../AppContext';
import { getStationPerformanceSummaryData, getAllPerformanceSummaryData } from '../Services/SessionsService';
import { getEndOfToday } from '../Utils/Dates';

export const StationsSummaryCard = ({idstations}) => {

    const context = useContext(AppContext);
    
    const [data, setData] = useState([]);

    const [dateRange, setDateRange] = useState([new Date('01/01/2017'), getEndOfToday()]);
    
    useEffect(()=> {
        async function getData() {

            if(idstations) {
                const singleStation = await getStationPerformanceSummaryData(context.token, idstations, dateRange[0], dateRange[1]);
                // console.log(singleStation);
                setData(singleStation);
            } else {
                const allStations = await getAllPerformanceSummaryData(context.token, context.activeView, dateRange[0], dateRange[1]);
                // console.log(allStations.length);
                setData(allStations);
            }
        }
        getData();
        
      },[context.token, context.activeView, dateRange, idstations]);


    return(
        <Card>
            <CardHeader>
                <CardTitle>Requests Summary</CardTitle>
                <CardHeaderCenter justifyContent="flex-end">
                    <CardHeaderDateRange dateRange={dateRange} setDateRange={setDateRange} />
                </CardHeaderCenter>
                <CardCSVExportButton data={data} filename="stations-performance.csv"/>
            </CardHeader>
            <CardMain className="card-main-chart-table">
                <StationsSummaryTable data={data} />
            </CardMain>                
        </Card>
    )
}

export default StationsSummaryCard;