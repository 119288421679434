import { StationsPerformanceCard } from './StationsPerformanceCard';
import { StationsSummaryCard } from './StationsSummaryCard';
import './StationReports.scss';
import MobileRotate from '../Components/MobileRotate/MobileRotate';

export const StationReports = () => {

    return(
        <div className="station-reports">
            <MobileRotate />
            <StationsSummaryCard />
            <StationsPerformanceCard />
        </div>
    )
}

export default StationReports;