import { useMemo } from 'react';

import Table from './Table';
 
export const UsersByCountryTable = ({data}) => {

    const columns = useMemo(
        () => [
            {
                Header: 'Country',
                accessor: 'country', // accessor is the "key" in the data
            },
            {
                Header: 'Users',
                accessor: 'total',
                Footer: info => {
                    // Only calculate total visits if rows change
                    const total = useMemo(
                      () =>
                        info.rows.reduce((sum, row) => row.values.total + sum, 0),
                      [info.rows]
                    )
      
                    return <>Total: {total.toLocaleString()}</>
                  },
            }
        ],
        []
    );

    const initialState = {
        sortBy: [
            {
                id: 'total',
                desc: true
            }
        ]
      };
    
    return (
        <Table data={data} columns={columns} initialState={initialState} footer/>
    );
}

