import {useContext, useState, useEffect} from 'react';
import { UsersByMonthChart } from "../Charts/UsersByMonthChart";
import { Card, CardTitle, CardHeader, CardCSVExportButton, CardHeaderCenter, CardMain } from "../Components/Card";

import { AppContext } from '../AppContext';
import { getCountries, getUsersByMonth } from "../Services/UsersService";

export const UsersByMonthCard = () => {

    const context = useContext(AppContext);

    const [data, setData] = useState([]);

    const [country, setCountry] = useState('ALL');
    const [countryData, setCountryData] = useState([{
		"name": "All Countries",
		"code": "ALL"
    }]);

    useEffect(() => {
        async function getCountryData() {
            const countries = await getCountries(context.token);
            setCountryData(countries);
        }
        getCountryData();
    },[context.token]);

    useEffect(() => {
        async function getData() {
            const result = await getUsersByMonth(context.token, country);
            // console.log(result);
            setData(result);
        }
        getData();
    },[context.token, country]);


    return(
        <Card>
            <CardHeader>
                <CardTitle>App Users by Month</CardTitle>
                <CardHeaderCenter>
                    <select value={country} onChange={(e)=>setCountry(e.target.value)}>
                        {countryData.map((country) =>
                            <option key={country.code} value={country.code}>{country.name}</option>
                        )}
                    </select>
                </CardHeaderCenter>
                <CardCSVExportButton data={data} filename={`users-by-month-${country}.csv`}/>
            </CardHeader>
            <CardMain >
                <UsersByMonthChart data={data}/>
            </CardMain>                
        </Card>
    )
}

export default UsersByMonthCard;