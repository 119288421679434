import './Widget.scss';
import FontAwesomeIcon from '../FontAwesome';

export const Widget = ({value, title, icon, color}) => 
    <div className="widget">

        <FontAwesomeIcon className="widget-icon" style={{backgroundColor: color}} icon={icon} />

        <div className="widget-text-container">
            <div className="widget-value" style={{color: color}}>
                {value}
            </div>
            <div className="widget-title">
                {title}
            </div>
        </div>

    </div>
