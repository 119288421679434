import {useContext, useState, useEffect} from 'react';
import { UsersByCountryChart } from "../Charts/UsersByCountryChart";
import { Card, CardTitle, CardHeader, CardCSVExportButton, CardHeaderCenter, CardMain } from "../Components/Card";
import { UsersByCountryTable } from "../Tables/UsersByCountryTable";

import { AppContext } from '../AppContext';
import { getUsersByCountry } from "../Services/UsersService";

export const UsersByCountryCard = () => {

    const context = useContext(AppContext);
    
    const [data, setData] = useState([]);
    
    useEffect(()=> {
      async function loadData() {
        try {
            const result = await getUsersByCountry(context.token);
            // console.log(result);
            setData(result);
        } catch (error) {
            console.log(error);
        }
      }
      loadData();
    },[context.token, context.activeView]);


    return(
        <Card>
            <CardHeader>
                <CardTitle>Users By Country</CardTitle>
                <CardHeaderCenter></CardHeaderCenter>
                <CardCSVExportButton data={data} filename="users-by-country.csv"/>
            </CardHeader>
            <CardMain className="card-main-chart-table">
                <UsersByCountryChart data={data}/>
                <UsersByCountryTable data={data}/>
            </CardMain>                
        </Card>
    )
}

export default UsersByCountryCard;