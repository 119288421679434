import { ResponsiveContainer, LineChart, XAxis, YAxis, Tooltip, Line } from "recharts";

export const SessionsHistoryChart = ({data}) => {

    return(
        <ResponsiveContainer width="99%" aspect={3}>
            {/* {data && data.length > 0 && */}
                <LineChart data={data}>
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dot={false} dataKey="accept_percentage" stroke="#4dbd74" name="Accepted %"/>
                    <Line type="monotone" dot={false} dataKey="decline_percentage" stroke="#f86c6b" name="Declined %"/>
                    <Line type="monotone" dot={false} dataKey="engaged_percentage" stroke="#63c2de" name="Phone Busy %"/>
                    <Line type="monotone" dot={false} dataKey="not_answered_percentage" stroke="#3498db" name="Not Answered %"/>
                    <Line type="monotone" dot={false} dataKey="hungup_percentage" stroke="#f8cb00" name="Hungup %"/>
                </LineChart>
            {/* } */}
        </ResponsiveContainer>
    );
}