import memoize from "lodash/memoize";

export const FetchPostJson = (url, json) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(json) // body data type must match "Content-Type" header
            });
            resolve(await response.json()); // parses JSON response into native JavaScript objects
        } catch (error) {
            reject(error);
        }
    })
}

export const FetchPostBlob = (url, json) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(json) // body data type must match "Content-Type" header
            });

            if(response.status !== 200) {
                reject(new Error('Recording not available'));
            } else {
                const blob = await response.blob();
                const blobUrl = URL.createObjectURL(blob);
                resolve(blobUrl);
            }
        } catch (error) {
            reject(error);
        }
    })
}

export const FetchGetJson = (url) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(url, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            });
            resolve(await response.json()); // parses JSON response into native JavaScript objects
        } catch (error) {
            reject(error);
        }
    })
}

export default FetchPostJson;

export const CacheFetchGetJson = memoize(FetchGetJson, (...args) => JSON.stringify(args));
export const CacheFetchPostJson = memoize(FetchPostJson, (...args) => JSON.stringify(args));
export const CacheFetchPostBlob = memoize(FetchPostBlob, (...args) => JSON.stringify(args));
// export default memoize(FetchPostJson, (...args) => JSON.stringify(args));