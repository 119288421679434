import Popup from 'reactjs-popup';
import FontAwesomeIcon from '../FontAwesome';

import './LoadingData.scss';

export const LoadingData = ({loading}) => {

    return(

                <Popup
                    open={loading}
                    position="center"
                    className="loading-popup"
                    closeOnDocumentClick={false}
                    closeOnEscape={false}
                    >

                        <div className="loading-popup-main">
                            <div className="loading-popup-text">Just getting your data</div>
                            <FontAwesomeIcon className="loading-popup-spinner" spin icon="fa-solid fa-spinner-third" />
                        </div>

                </Popup>
    );
}