import {useContext, useState} from 'react';
import { Card, CardTitle, CardHeader, CardHeaderCenter, CardMain } from "../Components/Card";
import { AppContext } from '../AppContext';
import './UsersCard.scss';
import Button from '../Components/Button';
import { getUserDetails } from '../Services/UsersService';
import { UsersTable } from '../Tables/UsersTable';

export const UsersCard = () => {

    const context = useContext(AppContext);

    const [data, setData] = useState([]);

    const [searchEnabled, setSearchEnabled] = useState(false);

    const [searchInput, setSearchInput] = useState('');

    const changeSearchInput = (input) => {
        setSearchInput(input);
        if(matchEmail(input)) {
            setSearchEnabled(true);
        } else {
            setSearchEnabled(false);
        }
    }

    const matchEmail = (input) => {
        const matchEmail = input.match(/@/);
        if(matchEmail) {
            return input.trim();
        }
    }

    const runSearch = async () => {
        const email = matchEmail(searchInput);
        const userData = await getUserDetails (context.token, email);
        setData(userData);
    }


    return(
        <Card className="raw-sessions-card">
            <CardHeader>
                <CardTitle>User Details</CardTitle>
                    <CardHeaderCenter>
                        <div className="users-card-inputs">
                            <input 
                                className="card-header-search"
                                value={searchInput}
                                onChange={(e) => changeSearchInput(e.target.value)}
                                placeholder="email address"
                            />
                            <Button onClick={runSearch} disabled={!searchEnabled}>Search</Button>
                        </div>
                    </CardHeaderCenter>
            </CardHeader>
            <CardMain className="raw-sessions-main-content">
                <UsersTable data={data} />
            </CardMain>                
        </Card>
    )
}

export default UsersCard;