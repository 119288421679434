import VirtualizedTable from './VirtualizedTable'; 
import Button from '../Components/Button';

import { useNavigate } from 'react-router-dom';
import FontAwesomeIcon from '../FontAwesome';
import { isMobile } from 'react-device-detect';

export const StationsPerformanceTable = ({data}) => {

    const navigate = useNavigate();

    const columns = [
        {
            Header: 'ID',
            accessor: 'idstations', // accessor is the "key" in the data
            width: 90
        },
        {
            Header: 'Name',
            accessor: 'name',
            width: 200
        },
        {
            Header: 'Total Requests',
            accessor: 'total',
            width: 110
        },
        {
            Header: 'Accepted',
            accessor: 'accept',
            width: 80
        },
        {
            Header: 'Declined',
            accessor: 'decline',
            width: 80
        },
        {
            Header: 'Success',
            accessor: 'answered_percentage',
            width: 80,
            FormatCell: ({rowData}) => {
                return rowData.answered_percentage + '%';
            },
        },
        {
            Header: 'Failed',
            accessor: 'failed_percentage',
            width: 80,
            FormatCell: ({rowData}) => {
                return rowData.failed_percentage + '%';
            },
        },
        {
            Header: 'Last Result',
            accessor: 'last_result',
            width: 150,
            FormatCell: ({rowData}) => {
                if(rowData.last_result === 'accept') return 'Accepted';
                if(rowData.last_result === 'decline') return 'Declined';
                if(rowData.last_result === 'hungup') return 'Hung Up';
                if(rowData.last_result === 'noanswer') return 'No Answer';
                if(rowData.last_result === 'busy') return 'Phone Busy';
                if(rowData.last_result === 'broken') return 'Phone Out of Service';
                if(rowData.status === 'unknown') return 'Unknown';
                return 'None';
            },
        },
        {
            Header: '',
            accessor: 'idstations',
            className: 'visible-column',
            width: 120,
            FormatCell: ({rowData}) => <Button onClick={()=>navigate(`/main/stations/${rowData.idstations}`)}><FontAwesomeIcon icon="fa-regular fa-eye" />&nbsp;&nbsp;&nbsp;More Details</Button>,
            disableSort: true,
        }
    ];

    const mobileColumns = [
        {
            Header: 'Name',
            accessor: 'name',
            width: 120
        },
        {
            Header: 'Reqs',
            accessor: 'total',
            width: 50
        },
        {
            Header: 'Accept',
            accessor: 'accept',
            width: 80
        },
        {
            Header: 'Decline',
            accessor: 'decline',
            width: 80
        },
        {
            Header: 'Success',
            accessor: 'answered_percentage',
            width: 85,
            FormatCell: ({rowData}) => {
                return rowData.answered_percentage + '%';
            },
        },
        {
            Header: 'Failed',
            accessor: 'failed_percentage',
            width: 80,
            FormatCell: ({rowData}) => {
                return rowData.failed_percentage + '%';
            },
        },
        {
            Header: 'Last Result',
            accessor: 'last_result',
            width: 100,
            FormatCell: ({rowData}) => {
                if(rowData.last_result === 'accept') return 'Accepted';
                if(rowData.last_result === 'decline') return 'Declined';
                if(rowData.last_result === 'hungup') return 'Hung Up';
                if(rowData.last_result === 'noanswer') return 'No Answer';
                if(rowData.last_result === 'busy') return 'Phone Busy';
                if(rowData.last_result === 'broken') return 'Phone Out of Service';
                if(rowData.status === 'unknown') return 'Unknown';
                return 'None';
            },
        },
        {
            Header: '',
            accessor: 'idstations',
            className: 'visible-column',
            width: 80,
            FormatCell: ({rowData}) => <Button onClick={()=>navigate(`/main/stations/${rowData.idstations}`)}><FontAwesomeIcon icon="fa-regular fa-eye" /></Button>,
            disableSort: true,
        }
    ];
    
    return (
        <VirtualizedTable data={data} columns={isMobile ? mobileColumns : columns} />
    )
}

