import './Button.scss';

export const Button = ({onClick, children, color, disabled}) => {
    switch (color) {
        case 'white':
            return (
                <div className={disabled ? "white-button-disabled" : "white-button"} onClick={disabled ? ()=>{} : onClick}>
                    {children}
                </div>
            );
        default:
            return (
                <div className={disabled ? "blue-button-disabled" : "blue-button"} onClick={disabled ? ()=>{} : onClick}>
                    {children}
                </div>
            );
    }
}

export default Button;