import FetchPostJson from "./Fetch";

import hostname from './Hostname';

export const getUsersByMonth = async (token, country) => {
    return (await FetchPostJson(`https://${hostname}/Stats/User/getUsersByMonth`, {token, country}));
}

export const getUsersByCountry = async (token) => {
    return (await FetchPostJson(`https://${hostname}/Stats/User/getUsersByCountry`, {token}));
}

export const getCountries = async (token) => {
    return (await FetchPostJson(`https://${hostname}/Stats/User/getCountries`, {token}));
}

export const getNumberOfTimesUsedPerUser = async (token, country) => {
    return (await FetchPostJson(`https://${hostname}/Stats/User/getNumberOfTimesUsedPerUser`, {token, country}));
}

export const getUserDetails = async (token, email) => {
    return (await FetchPostJson(`https://${hostname}/Users/getUserDetails`, {token, email}));
}