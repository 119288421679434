import {useState, useEffect} from 'react';

// import {Column, Table, AutoSizer} from 'react-virtualized';
import { Table, Column } from 'react-virtualized/dist/commonjs/Table';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import './VirtualizedTable.scss';

import { SortDirection, sortNumber, sortString, sortDate } from '../Utils/DataSort';

export const VirtualizedTable = ({data, columns}) => {

  const [tableData, setTableData] = useState(data);
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState(SortDirection.ASC);


  useEffect(()=> {
    setTableData(data);
  },[data]);

  function sort({ defaultSortDirection, event, sortBy, sortDirection }) {

    // console.log(defaultSortDirection, event, sortBy, sortDirection);
   
    let sortType = 'string'; // default if not specified
    let sortField;
    for(let i=0; i < columns.length; i++) {
      if(columns[i].accessor === sortBy) {
        sortType = columns[i].type ? columns[i].type : typeof data[0][sortBy];
        if(columns[i].sortField) {
          sortField = columns[i].sortField;
        }
        // console.log(sortType);
        break;
      }
    }

    let sortData = [];
    if(sortType === 'string') {
      sortData = sortString(data, sortDirection, sortField ? sortField : sortBy);
    } else if (sortType === 'number') {
      sortData = sortNumber(data, sortDirection, sortField ? sortField : sortBy);
    } else if (sortType === 'date') {
      sortData = sortDate(data, sortDirection, sortField ? sortField : sortBy);
    }

    setSortBy(sortBy);
    setSortDirection(sortDirection);
    setTableData(sortData);

  }

  return (
    <AutoSizer>
      {({height,width}) =>
        <Table
          width={width}
          height={height}
          headerHeight={50}
          rowHeight={50}
          rowCount={tableData.length}
          rowGetter={({index}) => tableData[index]}
          sort={sort}
          sortBy={sortBy}
          sortDirection={sortDirection}>

            {columns.map((column,id) => (
                <Column 
                  className={column.className}
                  key={id}
                  label={column.Header}
                  dataKey={column.accessor}
                  width={column.width}
                  cellRenderer={column.FormatCell ? column.FormatCell : undefined}
                  disableSort={column.disableSort ? column.disableSort : false}
                />
            ))}

        </Table>
      }
    </AutoSizer>
  );
}



export default VirtualizedTable;