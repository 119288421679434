import './Users.scss';
import MobileRotate from '../Components/MobileRotate/MobileRotate';
import UsersCard from './UsersCard';

export const Users = () => {

    return(
        <div className="users">
            <MobileRotate />
            <UsersCard />
         
        </div>
    )
}

export default Users;