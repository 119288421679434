import { Outlet } from "react-router-dom";
import { Footer } from "./Footer";
import { Header } from "./Header";

import './MainPage.scss';
import { Menu } from "./Menu";
import {MenuContext, MenuStateProvider} from './MenuContext';

export const MainPage = () => {

    return(
        <MenuStateProvider>
            <MenuContext.Consumer>
                {(menuState) => (
                    <div className="main-container">

                        <Header />
                        <div className="header-space" />

                        <div className="main-center">
                            <Menu />
                            <div className="main-content">

                                <div className="sub-content">
                                    <Outlet />
                                </div>

                                <Footer />

                            </div>
                        </div>
                    
                    </div>
                )}
            </MenuContext.Consumer>
        </MenuStateProvider>
    )
}

export default MainPage;