import { useContext, useEffect, useState } from "react";
import { ResponsiveContainer, AreaChart, Tooltip, Area } from "recharts";
import { AppContext } from "../AppContext";
import { getStationAverageResponseTimeByWeek, getAllAverageResponseTimeByWeek} from "../Services/SessionsService";

export const AverageResponseTimeChart = ({idstations}) => {

    const context = useContext(AppContext);

    const [data, setData] = useState([]);

    useEffect(() => {
        async function getData() {
            if(idstations) {
                const singleStation = await getStationAverageResponseTimeByWeek(context.token, idstations);
                // console.log(singleStation);
                setData(singleStation);
            } else {
                const allStations = await getAllAverageResponseTimeByWeek(context.token, context.activeView);
                console.log(allStations);
                setData(allStations);
            }
        }
        getData();
    },[context.activeView, context.token, idstations]);

    return(
        <ResponsiveContainer width="99%" aspect={3}>
            <AreaChart data={data}>
                {/* <XAxis dataKey="rating" /> */}
                {/* <YAxis /> */}
                <Tooltip
                    labelFormatter={(value) => data[value] ? `${data[value].date}` : ''}
                />
                {/* <Legend /> */}
                <Area  type="monotone" dataKey="seconds" stroke="#8884d8" fill="#8884d8" name="Average Seconds"/>
            </AreaChart>
        </ResponsiveContainer>
    );
}