import { useContext } from 'react';
import './Menu.scss';
import { MenuContext } from './MenuContext';
import { useNavigate } from 'react-router-dom';
import FontAwesomeIcon from '../FontAwesome';

export const Menu = () => {

    const menuContext = useContext(MenuContext);

    return(

        <div className="menu" style={{width: menuContext.open ? '240px' : '0px'}}>
            
                <div className="menu-main">
                    {menuContext.menu.map((group, id) => 
                        <MenuGroup key={id} name={group.name} icon={group.icon}>
                            {group.items.map((item, id) => 
                                <MenuItem key={id} name={item.name} icon={<FontAwesomeIcon icon={item.icon} />} path={item.path}/>
                            )}
                        </MenuGroup>
                    )}
                </div>

        </div>
    )
}

export const MenuGroup = ({name, children}) => {

    return(
        <div>
            <div className="menu-group">
                <span>{name}</span>
            </div>

            {children}
        </div>

    );
}

export const MenuItem = ({path, name, icon}) => {

    const menuContext = useContext(MenuContext);
    
    const navigate = useNavigate();

    const selectItem = () => {
        navigate(path);
        if(menuContext.mobile) {
            menuContext.setOpen(false);
        }
    }

    return(
        <div 
            className={"menu-item " + (window.location.pathname === path ? " menu-item-active" : "")}
            onClick={selectItem}
        >
            {icon && <span className="menu-item-icon">{icon}&nbsp;&nbsp;&nbsp;&nbsp;</span> }
            <span className="menu-item-name">{name}</span>
        </div>
    );
}