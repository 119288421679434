import { useContext, useEffect, useState } from "react";
import { ResponsiveContainer, BarChart, XAxis, Tooltip, Bar } from "recharts";
import { AppContext } from "../AppContext";
import { getAllOverallFeedback, getStationOverallFeedback } from "../Services/FeedbackService";

export const AverageRatingChart = ({idstations}) => {

    const context = useContext(AppContext);

    const [data, setData] = useState([]);

    useEffect(() => {
        async function getData() {
            if(idstations) {
                const singleStation = await getStationOverallFeedback(context.token, idstations);
                // console.log(singleStation);
                setData(singleStation);
            } else {
                const allStations = await getAllOverallFeedback(context.token, context.activeView);
                // console.log(allStations.length);
                setData(allStations);
            }
        }
        getData();
    },[context.activeView, context.token, idstations]);

    return(
        <ResponsiveContainer width="99%" aspect={3}>
            <BarChart data={data}>
                <XAxis dataKey="rating" />
                {/* <YAxis /> */}
                <Tooltip
                    labelFormatter={(value) => `${value} out of 5`}
                />
                {/* <Legend /> */}
                <Bar dataKey="ratingCount" fill="#8884d8" name="Number of Ratings"/>
            </BarChart>
        </ResponsiveContainer>
    );
}