import UsagePerUserCard from './UsagePerUserCard';
import UsersByCountryCard from './UsersByCountryCard';
import UsersByMonthCard from './UsersByMonthCard';
import './UserStats.scss';
import MobileRotate from '../Components/MobileRotate/MobileRotate';

export const UserStats = () => {

    return(
        <div className="user-stats">
            <MobileRotate />
            <UsersByCountryCard />
            <UsersByMonthCard />
            <UsagePerUserCard />
        </div>
    )
}

export default UserStats;