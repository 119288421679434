export const SortDirection = {ASC: "ASC", DESC: "DESC"};

export const sortNumber = (data, sortDirection, sortBy) => {

    const sortData = JSON.parse(JSON.stringify(data));
  
    if (sortDirection === SortDirection.ASC) {
      sortData.sort((a, b) => a[sortBy] - b[sortBy]);
    } else {
      sortData.sort((a, b) => b[sortBy] - a[sortBy])
    }
  
    return(sortData);
  
  }
  
export const sortString = (data, sortDirection, sortBy) => {

    const sortData = JSON.parse(JSON.stringify(data));

    if(sortDirection === SortDirection.ASC) {
        sortData.sort((a, b) => {
            const fa = a[sortBy].toLowerCase(),
                fb = b[sortBy].toLowerCase();
        
            if (fa > fb) {
                return -1;
            }
            if (fa < fb) {
                return 1;
            }
            return 0;
        });
    } else {
        sortData.sort((a, b) => {
            const fa = a[sortBy].toLowerCase(),
                fb = b[sortBy].toLowerCase();

            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });
    }

    return(sortData);
}

export const sortDate = (data, sortDirection, sortBy) => {

    const sortData = JSON.parse(JSON.stringify(data));

    if (sortDirection === SortDirection.ASC) {
        
        sortData.sort((a, b) => {
            if (a[sortBy] > b[sortBy]) {
                return -1;
            }
            if (a[sortBy] < b[sortBy]) {
                return 1;
            }
            return 0;
        });

    } else {
        
        sortData.sort((a, b) => {
            if (a[sortBy] < b[sortBy]) {
                return -1;
            }
            if (a[sortBy] > b[sortBy]) {
                return 1;
            }
            return 0;
        });

    }

    // console.log(sortData[0]);
    return(sortData);

}
