import { Card, CardTitle, CardHeader, CardMain } from "../Components/Card";
import { AverageRatingChart } from "../Charts/AverageRatingChart";

export const RatingsCard = ({idstations}) => {

    return(
        <Card>
            <CardHeader>
                <CardTitle>Ratings</CardTitle>
            </CardHeader>
            <CardMain className="card-main-chart-table">
                <AverageRatingChart idstations={idstations} />
            </CardMain>                
        </Card>
    )
}

export default RatingsCard;