import { ResponsiveContainer, PieChart, Tooltip, Pie, Cell } from "recharts";

// const COLORS = ['#8884d8', '#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
import COLORS from './ChartColors';

export const UsersByCountryChart = ({data}) => {

    return(
        <ResponsiveContainer width="99%" aspect={1}>
            <PieChart >
                <Tooltip />
                {/* <Legend /> */}
                <Pie data={data} dataKey="total" fill="#8884d8" nameKey="country">
                        {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
}