import Popup from 'reactjs-popup';
import './MobileRotate.scss';

import { isMobile } from 'react-device-detect';
import { useWindowWidth, useWindowHeight } from '@react-hook/window-size';

import MobileRotateImage from './rotate-mobile.svg';

export const MobileRotate = () => {

    const windowWidth = useWindowWidth();
    const windowHeight = useWindowHeight();
    
    return(
        <div>
            <Popup
                open={isMobile && (windowHeight > windowWidth)}
                position="center"
                className="mobile-rotate"
                closeOnDocumentClick={false}
                closeOnEscape={false}
                >

                    <div style={{backgroundColor: 'white', padding: '50px'}}>
                        <div className="mobile-rotate-text">
                            Please rotate your device to horizontal to view.
                        </div>
                        <img src={MobileRotateImage} alt="Turn phone to horizontal"/>
                    </div>

            </Popup>
        </div>
    );
}

export default MobileRotate;