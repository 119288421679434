import './Card.scss';
import FontAwesomeIcon from '../FontAwesome';
import { CSVLink } from "react-csv";
import { isMobile } from 'react-device-detect';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import './DateRangePicker.scss';

export const Card = ({children, className}) =>
    <div className={className ? `card ${className}`: "card"}>{children}</div>;

export const CardHeader = ({children}) =>
    <div className="card-header">{children}</div>

export const CardTitle = ({children, className}) =>
    <div className={className ? `card-title ${className}` : "card-title"}>{children}</div>

export const CardHeaderCenter = ({justifyContent, children}) =>
    <div className="card-center-items" style={justifyContent ? {justifyContent} : {}}>{children}</div>

export const CardHeaderSearch = ({onChange, value}) =>
    <input 
        className="card-header-search"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Search..."
    />

export const CardHeaderDateRange = ({dateRange, setDateRange}) => 
    <DateRangePicker
        onChange={(range)=>setDateRange(range)}
        value={dateRange}
        calendarIcon={<FontAwesomeIcon icon={["far", "calendar-range"]}/>}
        clearIcon={null}
        format="dd/MM/yyyy"
    />


export const CardCSVExportButton = ({data, filename}) => isMobile ? null :
    <CSVLink
            data={data}
            filename={filename}
            className="card-csv-export-button"
            target="_blank"
    >
        <FontAwesomeIcon icon="fa-regular fa-file-spreadsheet" />
        &nbsp;
        <FontAwesomeIcon icon="fa-regular fa-down-long" />
    </CSVLink>

export const CardMain = ({className, children}) =>
    <div className={className ? `card-main ${className}` : 'card-main'}>{children}</div>

export const CardFooter = ({children}) => 
    <div className="card-footer">
        {children}
    </div>
