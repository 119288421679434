import {useContext, useState, useEffect} from 'react';

import './StationsPerformanceCard.scss';

import { Card, CardTitle, CardHeader, CardCSVExportButton, CardHeaderCenter, CardMain, CardHeaderDateRange, CardHeaderSearch } from "../Components/Card";
import { StationsPerformanceTable } from "../Tables/StationsPerformanceTable";

import { AppContext } from '../AppContext';
import { getPerformance } from '../Services/SessionsService';

import { getEndOfToday } from '../Utils/Dates';

import { isMobile } from 'react-device-detect';

export const StationsPerformanceCard = () => {

    const context = useContext(AppContext);
    
    const [data, setData] = useState([]);

    const [dateRange, setDateRange] = useState([new Date('01/01/2017'), getEndOfToday()]);

    const [search, setSearch] = useState('');
    const [searchData, setSearchData] = useState([]);

    function runSearch(searchString) {

        setSearch(searchString);
        const filteredData = data.filter((item) => {
            return Object.values(item).join('').toLowerCase().includes(searchString.toLowerCase())
            });
        setSearchData(filteredData);
    }
    
    useEffect(()=> {
        async function loadData() {
          try {
              const result = await getPerformance(context.token, context.activeView, dateRange[0], dateRange[1]);
              setData(result);
              setSearchData(result);
          } catch (error) {
              console.log(error);
          }
        }
        loadData();
      },[context.token, context.activeView, dateRange]);


    return(
        <Card className="stations-performance-card">
            <CardHeader>
                <CardTitle>Stations Summary</CardTitle>
                <CardHeaderCenter justifyContent="flex-start">
                    <CardHeaderSearch value={search} onChange={runSearch} />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {!isMobile && <CardHeaderDateRange dateRange={dateRange} setDateRange={setDateRange} />}
                </CardHeaderCenter>
                <CardCSVExportButton data={data} filename="stations-performance.csv"/>
            </CardHeader>
            <CardMain className="stations-performance-main-content">
                <StationsPerformanceTable data={searchData} />
            </CardMain>                
        </Card>
    )
}

export default StationsPerformanceCard;