import {createContext, useEffect, useState} from 'react';
import { verifyToken } from './Services/AuthenticationService';

export const AppContext = createContext();

export const AppStateProvider = ({children}) => {

    const [token, setToken] = useState(null);
    const [verifyingToken, setVerifyingToken] = useState(false);
    const [email, setEmail] = useState('');
    const [stations, setStations] = useState([]);
    const [name, setName] = useState([]);
    const [phone, setPhone] = useState('');

    // Permissions
    const [permissions, setPermissions] = useState({
        superuser: false,
        testcalls: false,
        userstats: false,
        devicestats: false,
        brandstats: false,
        editstations: false,
    });

    // Views
    const [views, setViews] = useState([]);
    const [activeView, setActiveView] = useState(0);

    // Used to login the user if they have saved their auth token
    useEffect(()=>{
        async function verifyUser() {
            const existingToken = window.localStorage.getItem('token');
            if(existingToken) {
                setVerifyingToken(true);
                const response = await verifyToken(existingToken);
                if(response.result === 'success') {
                    setToken(existingToken);
                    setUserDetails(response);
                }
                else {
                    setVerifyingToken(false);
                }
            }
        }
        verifyUser();
    },[]);

    function setUserDetails(user) {
        setStations(user.stations);
        setName(user.name);
        setEmail(user.email);
        setPhone(user.phone);
        setPermissions({
            superuser: user.superuser,
            testcalls: user.testcalls,
            userstats: user.userstats,
            devicestats: user.devicestats,
            brandstats: user.brandstats,
            editstations: user.editstations,
        });
        setViews(user.views);
    }

    function logout() {
        setToken(null);
        setUserDetails({name: '', phone: '', email: '', stations: []});
        window.localStorage.removeItem('token');
    }

    return(
        <AppContext.Provider
            value = {{
                token,
                setToken,
                verifyingToken,
                setVerifyingToken,

                setUserDetails,
                name,
                phone,
                stations,
                permissions,

                email,
                setEmail,

                views,
                activeView,
                setActiveView,

                logout,
                
            }}
        >
            {children}
        </AppContext.Provider>
    )
}
