import './StationsTable.scss';
import VirtualizedTable from './VirtualizedTable';
import Button from '../Components/Button';
import { useNavigate } from 'react-router-dom';
import FontAwesomeIcon from '../FontAwesome';

import { isMobile } from 'react-device-detect';
 
export const StationsTable = ({data}) => {

    const navigate = useNavigate();

    const columns = [
            {
                Header: 'Station ID',
                accessor: 'idstations', // accessor is the "key" in the data
                width: 90,
                type: 'number'
            },
            {
                Header: 'Brand',
                accessor: 'brand',
                width: 130,
                FormatCell: ({rowData}) => <div className="station-table-brand"><img alt={`${rowData.brand} logo`} src={`https://static.fuelservice.org/brandicons/${rowData.brandid}.png`} /> {rowData.brand}</div>,
                type: 'string'
            },
            {
                Header: 'Name',
                accessor: 'name',
                width: 200,
                type: 'string'
            },
            {
                Header: 'Address',
                accessor: 'address1',
                width: 300,
                FormatCell: ({rowData}) => [rowData.address1, rowData.address2, rowData.town, rowData.city, rowData.postcode].filter((a) => a).join(', '),
                type: 'string'
            },
            {
                Header: 'Phone Number',
                accessor: 'phone1',
                width: 120,
                type: 'string'
            },
            {
                Header: 'Status',
                accessor: 'status',
                width: 80,
                FormatCell: ({rowData}) => {
                    if(rowData.status === 'active') return 'Active';
                    if(rowData.status === 'demo') return 'Demo Mode';
                    return 'Disabled';
                },
                type: 'string'
            },
            {
                Header: 'Actions',
                accessor: 'idstations',
                className: 'visible-column',
                width: 120,
                FormatCell: ({rowData}) => <Button onClick={()=>navigate(`/main/stations/${rowData.idstations}`)}><FontAwesomeIcon icon="fa-regular fa-eye" />&nbsp;&nbsp;&nbsp;View</Button>,
                disableSort: true,
            }
    ];

    const mobileColumns = [
        {
            Header: 'ID',
            accessor: 'idstations', // accessor is the "key" in the data
            width: 50,
            type: 'number'
        },
        {
            Header: 'Brand',
            accessor: 'brand',
            width: 40,
            FormatCell: ({rowData}) => <div className="station-table-brand"><img alt={`${rowData.brand} logo`} src={`https://static.fuelservice.org/brandicons/${rowData.brandid}.png`} /></div>,
            type: 'string'
        },
        {
            Header: 'Name',
            accessor: 'name',
            width: 200,
            type: 'string'
        },
        {
            Header: 'Status',
            accessor: 'status',
            width: 80,
            FormatCell: ({rowData}) => {
                if(rowData.status === 'active') return 'Active';
                if(rowData.status === 'demo') return 'Demo Mode';
                return 'Disabled';
            },
            type: 'string'
        },
        {
            Header: 'Actions',
            accessor: 'idstations',
            className: 'visible-column',
            width: 120,
            FormatCell: ({rowData}) => <Button onClick={()=>navigate(`/main/stations/${rowData.idstations}`)}><FontAwesomeIcon icon="fa-regular fa-eye" />&nbsp;&nbsp;&nbsp;View</Button>,
            disableSort: true,
        }
];
    
    return (
        <VirtualizedTable data={data} columns={isMobile ? mobileColumns : columns} />
    );
}
