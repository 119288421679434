
import './Footer.scss';

export const Footer = () => {
    const year = new Date().getFullYear();

    return (
        <div className="footerContainer">
            ©{year} Fuel Service Ltd 
        </div>
    )
}