import {useContext, useState, useEffect} from 'react';
import { AppContext } from '../AppContext';
import { getAllIssues } from '../Services/IssuesService';
import { useNavigate } from 'react-router-dom';

import { Widget } from "../Components/Widget";

import './IssuesWidget.scss';

export const IssuesWidget = () => {

    const context = useContext(AppContext);

    const [stationsNotWorking, setStationsNotWorking] = useState(0);
    const [brokenNumbers, setBrokenNumbers] = useState(0);

    const navigate = useNavigate();

    useEffect(()=> {
        async function loadData() {
          try {
              const result = await getAllIssues(context.token, context.activeView);
              let broken = 0;
              let notWorking = 0;
              result.forEach(issue => {
                  if(issue.last_result === 'broken') {
                      broken++;
                  } else {
                      notWorking++;
                  }
              });
              setBrokenNumbers(broken);
              setStationsNotWorking(notWorking);
              
          } catch (error) {
              console.log(error);
          }
        }
        loadData();
    },[context.token, context.activeView]);

    return(
        (brokenNumbers > 0 || stationsNotWorking > 0) ?
        <div className="issues-widget" onClick={()=>navigate('/main/currentissues')}>

            <div className="issues-widget-title">
                Current Issues
            </div>

            {brokenNumbers > 0 && 
                <Widget 
                    title="Phones Not Working"
                    value={brokenNumbers ? new Intl.NumberFormat().format(brokenNumbers) : ' '}
                    color="#a94442"
                    icon={["fas", "phone-slash"]}
                />
            }
            {stationsNotWorking > 0 &&
                <Widget 
                    title="Stations Not Responding"
                    value={stationsNotWorking ? new Intl.NumberFormat().format(stationsNotWorking) : ' '}
                    color="#a94442"
                    icon={["fas", "phone-missed"]}
                />
            }

        </div>

        :

        null
    )
}

export default IssuesWidget;