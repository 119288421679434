import { ResponsiveContainer, AreaChart, XAxis, YAxis, Tooltip, Area } from "recharts";

export const UsersByMonthChart = ({data}) => {

    return(
        <ResponsiveContainer width="99%" aspect={3}>
            <AreaChart data={data}>
                <XAxis dataKey="Date" />
                <YAxis />
                <Tooltip
                    // labelFormatter={(value) => `${dateRangeTitle} ${value}`}
                />
                <Area type="monotone" dot={false} dataKey="totalUsers" stroke="#8884d8" fill="#8884d8" name="Total Users"/>
                <Area type="monotone" dot={false} dataKey="NumberOfUsers" stroke="green" fill="green" name="New Users"/>
            </AreaChart>
        </ResponsiveContainer>
    );
}