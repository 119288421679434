import './Table.scss';

import {useState, useEffect} from 'react';

import { SortDirection, sortNumber, sortString, sortDate } from '../Utils/DataSort';

import FontAwesomeIcon from '../FontAwesome';

export const Table = ({data, columns}) => {

  const [tableData, setTableData] = useState(data);
  const [sortBy, setSortBy] = useState('');
  const [sortDirection, setSortDirection] = useState(SortDirection.ASC);

  useEffect(()=> {
    setTableData(data);
  },[data]);

  function sort(newSortBy) {

    let newSortDirection = SortDirection.ASC;
    if(newSortBy === sortBy) {
        if(sortDirection === SortDirection.ASC) {
            newSortDirection = SortDirection.DESC;
        }
    }

    let sortType = 'string'; // default if not specified
    let sortField;
    for(let i=0; i < columns.length; i++) {
      if(columns[i].accessor === newSortBy) {
        sortType = columns[i].type ? columns[i].type : typeof data[0][sortBy];
        if(columns[i].sortField) {
          sortField = columns[i].sortField;
        }
        break;
      }
    }

    let sortData = [];
    if(sortType === 'string') {
      sortData = sortString(data, newSortDirection, sortField ? sortField : newSortBy);
    } else if (sortType === 'number') {
      sortData = sortNumber(data, newSortDirection, sortField ? sortField : newSortBy);
    } else if (sortType === 'date') {
      sortData = sortDate(data, newSortDirection, sortField ? sortField : newSortBy);
    }


    setSortBy(newSortBy);
    setSortDirection(newSortDirection);
    setTableData(sortData);

  }

  return (
    <div className="table-container">
      <div className="tbl-content">
        <table >

          <thead>
            
              <tr>
                {columns.map((column,id) => (
                  <th 
                    key={id} 
                    style={column.headerStyle ? column.headerStyle : undefined}
                    className={column.headerClass ? column.headerClass : undefined}
                    onClick={()=>sort(column.accessor)}
                    >
                    {column.Header}
                    &nbsp;
                    {column.accessor === sortBy && sortDirection === SortDirection.ASC && <FontAwesomeIcon icon="fa-solid fa-caret-down" /> }
                    {column.accessor === sortBy && sortDirection === SortDirection.DESC && <FontAwesomeIcon icon="fa-solid fa-caret-up" /> }
                  </th>
                ))}
              </tr>
            
          </thead>

          <tbody>

              {tableData.map((row, rowId) => {
                    const cells = columns.map((column,id) => 
                        <td key={id} style={column.cellStyle ? column.cellStyle : undefined} className={column.cellClass ? column.cellClass : undefined}>
                            {column.FormatCell ?
                                column.FormatCell(row)
                                :
                                row[column.accessor]}
                        </td>
                    )
                    return(<tr key={rowId}>{cells}</tr>);
              })}

          </tbody>

        </table>
      </div>
    </div>
  );
}

export default Table;