import './WeekMonthYear.scss';

export const WeekMonthYear = ({value, setValue}) => 
    <>
        <div className={value === 'week' ? 'wmy-selected' : 'wmy-button'} onClick={()=>setValue('week')}>
            Week
        </div>
        <div className={value === 'month' ? 'wmy-selected' : 'wmy-button'} onClick={()=>setValue('month')}>
            Month
        </div>
        <div className={value === 'year' ? 'wmy-selected' : 'wmy-button'} onClick={()=>setValue('year')}>
            Year
        </div>
    </>