import './Dashboard.scss';
import { useState, useEffect, useContext } from 'react';
import IssuesWidget from "./IssuesWidget";
import { LiveStationsWidget } from "./LiveStationsWidget";
import { RatingWidget } from "./RatingWidget";
import { WaitTimeWidget } from "./WaitTimeWidget";
import { CustomersWidget } from "./CustomersWidget";
import { AppContext } from "../AppContext";
import { getDashboardSummary } from '../Services/SummaryService';
import { SessionsHistoryCard } from './SessionsHistoryCard';

export const Dashboard = () => {

    const context = useContext(AppContext);

    const [stationCounts, setStationCounts] = useState({active: 0, disabled: 0, demo: 0});
    const [averageRating, setAverageRating] = useState(0);
    const [averageResponseTime, setAverageResponseTime] = useState({seconds: 0, minutes: 0});
    const [totalCustomers, setTotalCustomers] = useState(0);

    useEffect(() => {
        async function getData() {
            const data = await getDashboardSummary(context.token, context.activeView);
            // console.log(data);
            setStationCounts(data.stationCounts);
            setAverageRating(data.averageRating);
            setAverageResponseTime(data.averageResponseTime);
            setTotalCustomers(data.totalCustomers);
        }
        getData();
    },[context.activeView, context.token]);

    return(
        <div className="dashboard">
            <div className="dashboard-row">
                <LiveStationsWidget number={stationCounts.active}/>
                <RatingWidget number={averageRating} />
                <WaitTimeWidget number={averageResponseTime.minutes}/>
                <CustomersWidget number={totalCustomers} />
            </div>
            <IssuesWidget />
            <SessionsHistoryCard />
        </div>
    )
}

export default Dashboard;