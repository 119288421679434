import {useContext, useState, useEffect} from 'react';

import './SessionsHistoryCard.scss';

import { Card, CardTitle, CardHeader, CardCSVExportButton, CardMain, CardFooter } from "../Components/Card";
import { SessionsHistoryChart } from "../Charts/SessionsHistoryChart";
import { WeekMonthYear } from "../Components/WeekMonthYear";
import { AppContext } from '../AppContext';

import { breakdownByDate, getSessionsSummary } from "../Services/SessionsService";

export const SessionsHistoryCard = () => {

    const context = useContext(AppContext);
    
    const [data, setData] = useState([]);
    const [summaryData, setSummaryData] = useState({
        "accepted": {
            "total": 0,
            "percent": 0
        },
        "declined": {
            "total": 0,
            "percent": 0
        },
        "hungup": {
            "total": 0,
            "percent": 0
        },
        "notanswered": {
            "total": 0,
            "percent": 0
        },
        "engaged": {
            "total": 0,
            "percent": 0
        }
    });

    const [dateType, setDateType] = useState('month');

    useEffect(() => {
        async function getData() {
            const breakdownData = await breakdownByDate(context.token, context.activeView, dateType);
            setData(breakdownData);

            const sessionsSummaryData = await getSessionsSummary(context.token, context.activeView);
            setSummaryData(sessionsSummaryData);
        }
        getData();
    },[context.activeView, context.token, dateType]);

    return(
        <Card>
            <CardHeader>
                <CardTitle>Summary of Requests</CardTitle>
                <CardCSVExportButton data={data} filename="stations-performance.csv"/>
            </CardHeader>
            <CardMain>
                <div>
                    <SessionsHistoryChart data={data} />
                    <div className="sessions-history-date-type">
                        <WeekMonthYear value={dateType} setValue={setDateType} />
                    </div>
                </div>
            </CardMain>      
            <CardFooter>
                <div className="sessions-history-card-footer">
                    <FooterItem title="Accepted" text={`${new Intl.NumberFormat().format(summaryData.accepted.total)} Requests (${summaryData.accepted.percent.toFixed(0)}%)`} percentage={summaryData.accepted.percent} color="#4dbd74" />
                    <FooterItem title="Declined" text={`${new Intl.NumberFormat().format(summaryData.declined.total)} Requests (${summaryData.declined.percent.toFixed(0)}%)`} percentage={summaryData.declined.percent} color="#f86c6b" />
                    <FooterItem title="Phone Busy" text={`${new Intl.NumberFormat().format(summaryData.engaged.total)} Requests (${summaryData.engaged.percent.toFixed(0)}%)`} percentage={summaryData.engaged.percent} color="#63c2de" />
                    <FooterItem title="Not Answered" text={`${new Intl.NumberFormat().format(summaryData.notanswered.total)} Requests (${summaryData.notanswered.percent.toFixed(0)}%)`} percentage={summaryData.notanswered.percent} color="#3498db" />
                    <FooterItem title="Hung Up" text={`${new Intl.NumberFormat().format(summaryData.hungup.total)} Requests (${summaryData.hungup.percent.toFixed(0)}%)`} percentage={summaryData.hungup.percent} color="#f8cb00" />
                </div>
            </CardFooter>          
        </Card>
    )
}

const FooterItem = ({title, text, color, percentage}) => 
    <div className="sessions-history-card-footer-item">
        <div className="sessions-history-card-footer-item-title" style={{color: color}}>{title}</div>
        <div className="sessions-history-card-footer-item-text">{text}</div>
        <div className="sessions-history-card-footer-item-percentage" style={{backgroundColor: color, width: percentage + '%'}}></div>
    </div>

export default SessionsHistoryCard;