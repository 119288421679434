import { Card, CardTitle, CardHeader, CardMain } from "../Components/Card";
import { AverageResponseTimeChart } from "../Charts/AverageResponseTimeChart";

export const WaitTimeCard = ({idstations}) => {

    return(
        <Card>
            <CardHeader>
                <CardTitle>Customer Wait Time</CardTitle>
            </CardHeader>
            <CardMain className="card-main-chart-table">
                <AverageResponseTimeChart idstations={idstations}/>
            </CardMain>                
        </Card>
    )
}

export default WaitTimeCard;