import {createContext, useState, useContext, useEffect} from 'react';
import { AppContext } from '../AppContext';

import { useWindowWidth } from '@react-hook/window-size';

import { isMobile } from 'react-device-detect';

const minMobileWidth = 600;

export const MenuContext = createContext();

export const MenuStateProvider = ({children}) => {

    const [open, setOpen] = useState(window.innerWidth > minMobileWidth);

    const toggleOpen = () => setOpen(!open);

    const appContext = useContext(AppContext);

    const [authorizedMenu, setAuthorizedMenu] = useState([]);

    const windowWidth = useWindowWidth();

    const [mobile, setMobile] = useState(window.innerWidth < minMobileWidth);

    useEffect(()=> {

        if (appContext.permissions.superuser) {
            setAuthorizedMenu(menu);
        } else {
            const menuCopy = JSON.parse(JSON.stringify(menu));
            const tempMenu = [];
            
            for(let i = 0; i < menuCopy.length; i++) {
                const allowedItems = allowedMenuItems(menuCopy[i].items);
                if(allowedItems.length > 0) {
                    menuCopy[i].items = allowedItems;
                    tempMenu.push(menuCopy[i]);
                }
            }
            
            setAuthorizedMenu(tempMenu);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appContext.permissions]);

    // open or close the menu based on size when resizing
    useEffect(()=> {
        setMobile(windowWidth < minMobileWidth || isMobile);
        setOpen(windowWidth > minMobileWidth && !isMobile);
    },[windowWidth]);

    function allowedMenuItems(items) {
        const tempMenu = [];
        for (let i=0; i < items.length; i++) {

            if(items[i].permission && appContext.permissions[items[i].permission]) {
                tempMenu.push(items[i]);
            } else if (items[i].permission) {
                continue;
            } else {
                tempMenu.push(items[i]);
            }
        }
        return(tempMenu);

    }
    
    return(
        <MenuContext.Provider
            value = {{
                open,
                setOpen,
                toggleOpen,
                mobile,

                menu: authorizedMenu,
            }}
        >
            {children}
        </MenuContext.Provider>
    )
}

const menu = [
    {
        name: "",
        items: [
            {
                name: "Dashboard",
                path: '/main',
                icon: "fa-regular fa-gauge-max"
            },
        ]
    },
    {
        name: "Stations",
        items: [
            {
                name: "Stations",
                path: '/main/stations',
                icon: "fa-regular fa-gas-pump"
            },
        ]
    },
    {
        name: "Reports",
        // icon: <FontAwesomeIcon icon={["far", "calendar-alt"]} />,
        open: true,
        items: [
            {
                name: "Stations Summary",
                path: '/main/stationreports',
                icon: "fa-regular fa-chart-line-up"
            },
            {
                name: "Current Issues",
                path: '/main/currentissues',
                icon: "fa-regular fa-bell-on"
            },
            {
                name: "Customer Feedback",
                path: '/main/feedback',
                icon: "fa-regular fa-comment-smile"
            },
        ]
    },
    {
        name: "Service Stats",
        // icon: <FontAwesomeIcon icon={["far", "calendar-alt"]} />,
        open: true,
        items: [
            {
                name: "User Statistics",
                path: '/main/userstats',
                icon: "fa-regular fa-users",
                permission: 'userstats'
            },
            {
                name: "Device Statistics",
                path: '/main/devicestats',
                icon: "fa-regular fa-laptop-mobile",
                permission: 'devicestats'
            },
            {
                name: "Country Statistics",
                path: '/main/countrystats',
                icon: "fa-regular fa-globe",
                permission: 'superuser'
            },
            {
                name: "Brand Statistics",
                path: '/main/brandstats',
                icon: "fa-regular fa-copyright",
                permission: 'brandstats'
            },
            {
                name: "Fuel Statistics",
                path: '/main/fuelstats',
                icon: "fa-regular fa-gas-pump",
                permission: 'superuser'
            },

        ]
    },
    {
        name: "Station Testing",
        // icon: <FontAwesomeIcon icon={["far", "calendar-alt"]} />,
        open: true,
        items: [
            {
                name: "Test Runs",
                path: '/main/testruns',
                icon: "fa-regular fa-phone-arrow-up-right",
                permission: 'testcalls'
            },
        ]
    },
    {
        name: "Admin",
        // icon: <FontAwesomeIcon icon={["far", "calendar-alt"]} />,
        open: true,
        items: [
            {
                name: "Users",
                path: '/main/users',
                icon: "fa-regular fa-users",
                permission: 'superuser'
            },
            {
                name: "Sessions",
                path: '/main/sessions',
                icon: "fa-regular fa-users",
                permission: 'superuser'
            },
            {
                name: "Shell Admin Users",
                path: '/main/shelladminusers',
                icon: "fa-regular fa-users",
                permission: 'superuser'
            },

        ]
    },

];