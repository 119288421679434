import './StationsTable.scss';
import VirtualizedTable from './VirtualizedTable';
import Button from '../Components/Button';
import { useNavigate } from 'react-router-dom';
import FontAwesomeIcon from '../FontAwesome';
import { isMobile } from 'react-device-detect';
 
export const NonWorkingStationsTable = ({data}) => {

    const navigate = useNavigate();

    const columns = [
            {
                Header: 'Station ID',
                accessor: 'idstations', // accessor is the "key" in the data
                width: 90,
                type: 'number'
            },
            {
                Header: 'Name',
                accessor: 'name',
                width: 200,
                FormatCell: ({rowData}) => <div className="station-table-brand"><img alt={`${rowData.brand} logo`} src={`https://static.fuelservice.org/brandicons/${rowData.brandid}.png`} /> {rowData.name}</div>,
                type: 'string'
            },
            {
                Header: 'Address',
                accessor: 'address1',
                width: 300,
                FormatCell: ({rowData}) => [rowData.address1, rowData.address2, rowData.town, rowData.city, rowData.postcode].filter((a) => a).join(', '),
                type: 'string'
            },
            {
                Header: 'Phone Number',
                accessor: 'formatted_phone_number',
                width: 120,
                type: 'string'
            },
            {
                Header: 'Failed Requests',
                accessor: 'num_sessions', // accessor is the "key" in the data
                width: 90,
                type: 'number'
            },
            {
                Header: 'Last Result',
                accessor: 'last_result',
                width: 160,
                FormatCell: formatResult,
                type: 'string'
            },
            {
                Header: 'Actions',
                accessor: 'idstations',
                className: 'visible-column',
                width: 120,
                FormatCell: ({rowData}) => <Button onClick={()=>navigate(`/main/stations/${rowData.idstations}`)}><FontAwesomeIcon icon="fa-regular fa-eye" />&nbsp;&nbsp;&nbsp;View</Button>,
                disableSort: true,
            }
    ];

    const mobileColumns = [
        {
            Header: 'ID',
            accessor: 'idstations', // accessor is the "key" in the data
            width: 60,
            type: 'number'
        },
        {
            Header: 'Name',
            accessor: 'name',
            width: 240,
            FormatCell: ({rowData}) => <div className="station-table-brand"><img alt={`${rowData.brand} logo`} src={`https://static.fuelservice.org/brandicons/${rowData.brandid}.png`} /> {rowData.name}</div>,
            type: 'string'
        },
        {
            Header: 'Phone Number',
            accessor: 'formatted_phone_number',
            width: 140,
            type: 'string'
        },
        {
            Header: 'Failed Reqs',
            accessor: 'num_sessions', // accessor is the "key" in the data
            width: 50,
            type: 'number'
        },
        {
            Header: 'Last Result',
            accessor: 'last_result',
            width: 140,
            FormatCell: formatResult,
            type: 'string'
        },
        {
            Header: 'Actions',
            accessor: 'idstations',
            className: 'visible-column',
            width: 120,
            FormatCell: ({rowData}) => <Button onClick={()=>navigate(`/main/stations/${rowData.idstations}`)}><FontAwesomeIcon icon="fa-regular fa-eye" /></Button>,
            disableSort: true,
        }
];
    
    return (
        <VirtualizedTable data={data} columns={isMobile ? mobileColumns : columns} />
    );
}

const formatResult = ({rowData}) => {
    switch(rowData.last_result) {
        case 'hungup': return 'Hung Up Phone';
        case 'busy': return 'Phone Busy';
        case 'noanswer': return 'Phone Not Answered';
        case 'unknown': 
        default: 
            return 'Unknown';
    }
}