import {useContext, useState, useEffect} from 'react';
import { Card, CardTitle, CardHeader, CardCSVExportButton, CardHeaderCenter, CardMain, CardHeaderSearch } from "../Components/Card";
import { AppContext } from '../AppContext';
import { FeedbackTable } from '../Tables/FeedbackTable';
import { getAllFeedback, getStationFeedback } from '../Services/FeedbackService';
import './FeedbackCard.scss';

export const FeedbackCard = ({idstations}) => {

    const context = useContext(AppContext);

    const [data, setData] = useState([]);

    const [search, setSearch] = useState('');
    const [searchData, setSearchData] = useState([]);

    function runSearch(searchString) {

        setSearch(searchString);
        const filteredData = data.filter((item) => {
            return Object.values(item).join('').toLowerCase().includes(searchString.toLowerCase())
            });
        setSearchData(filteredData);
    }

    useEffect(() => {
        async function getData() {
            if(idstations) {
                const singleStation = await getStationFeedback(context.token, idstations);
                singleStation.forEach(feedback => {
                    feedback.timestamp = new Date(feedback.timestamp);
                });
                setData(singleStation);
                setSearchData(singleStation);
            } else {
                const allStations = await getAllFeedback(context.token, context.activeView);
                allStations.forEach(feedback => {
                    feedback.timestamp = new Date(feedback.timestamp);
                });
                console.log(allStations);
                setData(allStations);
                setSearchData(allStations);
            }
        }
        getData();
    },[context.token, context.activeView, idstations]);


    return(
        <Card className="feedback-card">
            <CardHeader>
                <CardTitle>Customer Feedback</CardTitle>
                <CardHeaderCenter>
                    <CardHeaderSearch value={search} onChange={runSearch} />
                </CardHeaderCenter>
                <CardCSVExportButton data={data} filename={`feedback.csv`}/>
            </CardHeader>
            <CardMain className="feedback-main-content">
                <FeedbackTable data={searchData} />
            </CardMain>                
        </Card>
    )
}

export default FeedbackCard;