import FetchPostJson from "./Fetch";

import hostname from './Hostname';

export const getAllStations = async (token, viewId) => {
    return (await FetchPostJson(`https://${hostname}/Stations/getAllStations`, {token, viewId}));
}

export const getStationData = async (token, idstations) => {
    return (await FetchPostJson(`https://${hostname}/Stations/getStationData`, {token, idstations}));
}

export const updateStation = async (token, idstations, data) => {
    return (await FetchPostJson(`https://${hostname}/Stations/updateStation`, {token, idstations, data}));
}