import { useMemo } from 'react';

import Table from './Table';

import { isMobile } from 'react-device-detect';
 
export const FeedbackTable = ({data}) => {

    const columns = useMemo(
        () => [
            {
                Header: 'Date',
                accessor: 'date', // accessor is the "key" in the data
                // FormatCell: ({rowData})=> rowData.timestamp.toLocaleString(),
                width: 100,
                type: 'date',
                sortField: 'timestamp'
            },
            {
                Header: 'Station ID',
                accessor: 'idstations',
                width: 60
            },
            {
                Header: 'Station Name',
                accessor: 'name',
                width: 150
            },
            {
                Header: 'Feedback',
                accessor: 'feedback',
                width: 500
            },
            {
                Header: 'Rating',
                accessor: 'rating',
                width: 60
            },
           
        ],
        []
    );

    const mobileColumns = useMemo(
        () => [
            {
                Header: 'Date',
                accessor: 'date', // accessor is the "key" in the data
                // FormatCell: ({rowData})=> rowData.timestamp.toLocaleString(),
                width: 55,
                type: 'date',
                sortField: 'timestamp'
            },
            {
                Header: 'ID',
                accessor: 'idstations',
                width: 25
            },
            {
                Header: 'Station Name',
                accessor: 'name',
                width: 100
            },
            {
                Header: 'Feedback',
                accessor: 'feedback',
                width: 220
            },
            {
                Header: 'Rating',
                accessor: 'rating',
                width: 40
            },
           
        ],
        []
    );
    
    return (
        <Table data={data} columns={isMobile ? mobileColumns : columns} />
    );
}

