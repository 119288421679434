import FetchPostJson from "./Fetch";

import hostname from './Hostname';

export const getAllSessions = async (token, viewId, start, end) => {
    return (await FetchPostJson(`https://${hostname}/Stats/Sessions/getAllSessions`, {token, viewId, start, end}));
}

export const getStationSessions = async (token, idstations, start, end) => {
    return (await FetchPostJson(`https://${hostname}/Stats/Sessions/getAllSessions`, {token, idstations, start, end}));
}

export const byHour = async (token, viewId, start, end) => {
    return (await FetchPostJson(`https://${hostname}/Stats/Sessions/byHour`, {token, viewId, start, end}));
}

export const getAverageResponseTime = async (token, viewId) => {
    return (await FetchPostJson(`https://${hostname}/Stats/Sessions/getAverageResponseTime`, {token, viewId}));
}

export const getAllAverageResponseTimeByWeek = async (token, viewId) => {
    return (await FetchPostJson(`https://${hostname}/Stats/Sessions/getAverageResponseTimeByWeek`, {token, viewId}));
}

export const getStationAverageResponseTimeByWeek = async (token, idstations) => {
    return (await FetchPostJson(`https://${hostname}/Stats/Sessions/getAverageResponseTimeByWeek`, {token, idstations}));
}

export const breakdownByDate = async (token, viewId, daterange) => {
    return (await FetchPostJson(`https://${hostname}/Stats/Sessions/breakdownByDate`, {token, viewId, daterange}));
}

export const getPerformance = async (token, viewId, start, end) => {
    return (await FetchPostJson(`https://${hostname}/Stats/Sessions/getPerformance`, {token, viewId, start, end}));
}

export const getAllPerformanceSummaryData = async (token, viewId, start, end) => {
    return (await FetchPostJson(`https://${hostname}/Stats/Sessions/getPerformanceSummaryData`, {token, viewId, start, end}));
}

export const getStationPerformanceSummaryData = async (token, idstations, start, end) => {
    return (await FetchPostJson(`https://${hostname}/Stats/Sessions/getPerformanceSummaryData`, {token, idstations, start, end}));
}

export const getSessionsSummary = async (token, viewId) => {
    return (await FetchPostJson(`https://${hostname}/Stats/Sessions/getSessionsSummary`, {token, viewId}));
}

export const getStationResults = async (token, idstations) => {
    return (await FetchPostJson(`https://${hostname}/Stats/Sessions/getStationResults`, {token, idstations}));
}

export const getSessionsByStation = async (token, idstations) => {
    return (await FetchPostJson(`https://${hostname}/SessionDetails/getSessionsByStation`, {token, idstations}));
}

export const getSessionsByEmail = async (token, email) => {
    return (await FetchPostJson(`https://${hostname}/SessionDetails/getSessionsByEmail`, {token, email}));
}