import './Feedback.scss';
import FeedbackCard from './FeedbackCard';
import RatingsCard from './RatingsCard';
import WaitTimeCard from './WaitTimeCard';
import MobileRotate from '../Components/MobileRotate/MobileRotate';


export const Feedback = () => {

    return(
        <div className="feedback">
            <MobileRotate />
            <div className="feedback-rating-wait">
                <RatingsCard />
                <WaitTimeCard />
            </div>
            <FeedbackCard />
        </div>
    )
}

export default Feedback;