import { useState, useEffect} from 'react';

import { Card, CardTitle, CardHeader, CardCSVExportButton, CardHeaderCenter, CardMain, CardHeaderSearch } from "../Components/Card";
import { NonWorkingStationsTable } from "../Tables/NonWorkingStationsTable";

export const BrokenPhoneCard = ({data}) => {

    const [search, setSearch] = useState('');
    const [searchData, setSearchData] = useState([]);

    function runSearch(searchString) {

        setSearch(searchString);
        const filteredData = data.filter((item) => {
            return Object.values(item).join('').toLowerCase().includes(searchString.toLowerCase())
            });
        setSearchData(filteredData);
    }

    useEffect(()=> {
            setSearch('');
            setSearchData(data);
    },[data]);

    return(
        <Card className="issues-card">
            <CardHeader>
                <CardTitle>Stations Not Responding</CardTitle>
                <CardHeaderCenter justifyContent="flex-start">
                   <CardHeaderSearch value={search} onChange={runSearch} />
                </CardHeaderCenter>
                <CardCSVExportButton data={data} filename="non-working-stations.csv"/>
            </CardHeader>
            <CardMain className="issues-main-content">
                <NonWorkingStationsTable data={searchData} />
            </CardMain>                
        </Card>
    )
}

export default BrokenPhoneCard;