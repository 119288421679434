import Table from './Table';
 
export const UsersTable = ({data}) => {

    const columns = [
            {
                Header: 'Email',
                accessor: 'email', // accessor is the "key" in the data
                type: 'string'
            },
            {
                Header: 'Phone Country Code',
                accessor: 'countrycode', // accessor is the "key" in the data
                type: 'string'
            },
            {
                Header: 'Phone Number',
                accessor: 'phone', // accessor is the "key" in the data
                type: 'string'
            },
            {
                Header: 'Language',
                accessor: 'language', // accessor is the "key" in the data
                type: 'string'
            },
            {
                Header: 'Country',
                accessor: 'country', // accessor is the "key" in the data
                type: 'string'
            },
            {
                Header: 'Device',
                accessor: 'devicemodel', // accessor is the "key" in the data
                type: 'string'
            },
            {
                Header: 'OS',
                accessor: 'osname', // accessor is the "key" in the data
                type: 'string'
            },
            {
                Header: 'OS Version',
                accessor: 'osversion', // accessor is the "key" in the data
                type: 'string'
            },
            {
                Header: 'fuelService Version',
                accessor: 'appversion', // accessor is the "key" in the data
                type: 'string'
            },
            {
                Header: 'Created',
                accessor: 'created', // accessor is the "key" in the data
                type: 'string'
            },
            {
                Header: 'Last Login',
                accessor: 'lastlogin', // accessor is the "key" in the data
                type: 'string'
            },
            
    ];

    const initialState = {
        sortBy: [
            {
                id: 'lastlogin',
                desc: true
            }
        ]
      };
    
    return (
        <Table data={data} columns={columns} initialState={initialState}/>
    );
}

