import FetchPostJson from "./Fetch";

import hostname from './Hostname';

export const getAllIssues = async (token, viewId) => {
    return (await FetchPostJson(`https://${hostname}/Stats/Errors/getStationErrors`, {token, viewId}));
}

export const getStationIssues = async (token, idstations) => {
    return (await FetchPostJson(`https://${hostname}/Stats/Errors/getStationErrors`, {token, idstations}));
}