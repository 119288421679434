import FetchPostJson from "./Fetch";

import hostname from './Hostname';

export const getAllFeedback = async (token, viewId, start, end) => {
    return (await FetchPostJson(`https://${hostname}/Stats/Feedback/getFeedback`, {token, viewId, start, end}));
}

export const getStationFeedback = async (token, idstations, start, end) => {
    return (await FetchPostJson(`https://${hostname}/Stats/Feedback/getFeedback`, {token, idstations, start, end}));
}

export const getAllOverallFeedback = async (token, viewId) => {
    return (await FetchPostJson(`https://${hostname}/Stats/Feedback/getOverallFeedback`, {token, viewId}));
}

export const getStationOverallFeedback = async (token, idstations) => {
    return (await FetchPostJson(`https://${hostname}/Stats/Feedback/getOverallFeedback`, {token, idstations}));
}

export const getAverageRating = async (token, viewId) => {
    return (await FetchPostJson(`https://${hostname}/Stats/Feedback/getAverageRating`, {token, viewId}));
}

export const getAverageRatingByWeek = async (token, viewId) => {
    return (await FetchPostJson(`https://${hostname}/Stats/Feedback/getAverageRatingByWeek`, {token, viewId}));
}

// export const getAverageRatingByStation = async (token, viewId) => {
//     return (await FetchPostJson(`https://${hostname}/Stats/Feedback/getAverageRatingByStation`, {token, viewId}));
// }