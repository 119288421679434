import './Stations.scss';

import StationsCard from './StationsCard';
import MobileRotate from '../Components/MobileRotate/MobileRotate';

export const Stations = () => {

    return(
        <div className="stations">
            <MobileRotate />
            <StationsCard />
        </div>
    )
}

export default Stations;